import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const MembershipCashPlan = React.lazy(() =>
    import('../MembershipCashPlan')
);
const membershipCashPlanRoutesRegistry = [
    {
        path: PAGE_URL.MEMBERSHIP_CASH_PLAN_PAGE,
        element: <MembershipCashPlan />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default membershipCashPlanRoutesRegistry;
