import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const PromptGenAi = React.lazy(() =>
    import('../PromptGenAi')
);
const promptGenAiRoutesRegistry = [
    {
        path: PAGE_URL.PROMPT_GEN_AI_PAGE,
        element: <PromptGenAi />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default promptGenAiRoutesRegistry;
