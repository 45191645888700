import {
  UilBell,
  UilChannel,
  UilChartPie,
  UilFire,
  UilGift,
  UilObjectUngroup,
  UilSetting,
  UilShoppingBag,
  UilObjectGroup,
  UilUsersAlt,
  UilFilesLandscapesAlt,
  UilBooks,
  UilNewspaper,
  UilCreateDashboard,
  UilGoogleDriveAlt,
} from '@iconscout/react-unicons';
import React from "react";
import UilMoneyStack from "@iconscout/react-unicons/icons/uil-money-stack";
import menuRegistry from "./MenuLoader";
import RESOURCE_KEY from "@/constants/resource-key";
const MenuUrl = {
  WORKFLOW_DEFINITION_PAGE: `/admin/sim-workflow/workflowDefs`,
  WORKFLOW_SEARCH_PAGE: `/admin/sim-workflow/workflows`,
  WORKFLOW_TASK_QUEUE_PAGE: `/admin/sim-workflow/taskQueue`,
  WORKFLOW_WORKBENCH_PAGE: `/admin/sim-workflow/workbench`
}

const menuConfig = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    icon: <UilCreateDashboard />,
    children: [
      {
        ...menuRegistry['crm_dashboard1']
      },
      {
        ...menuRegistry['crm_dashboard10']
      },
      {
        ...menuRegistry['crm_dashboard11'],
      },
      {
        ...menuRegistry['crm_dashboard7']
      }
    ],
  },
  {
    label: 'File Explorer',
    key: 'file_manager',
    icon: <UilGoogleDriveAlt />,
    children: [
      {
        ...menuRegistry['core_fileManager'],
        label:  "File Explorer"
      }
    ],
  },
  {
    label: 'Workflow',
    key: 'sim-workflow',
    icon: <UilChannel />,
    children: [
      {
        label: "Definitions",
        link: MenuUrl.WORKFLOW_DEFINITION_PAGE,
        key: MenuUrl.WORKFLOW_DEFINITION_PAGE,
        resourceKey: RESOURCE_KEY.WORKFLOW_CORE,
      },
      {
        label: "Executions",
        link: MenuUrl.WORKFLOW_SEARCH_PAGE,
        key: MenuUrl.WORKFLOW_SEARCH_PAGE,
        resourceKey: RESOURCE_KEY.WORKFLOW_CORE,
      },
      {
        label: "Task Queues",
        link: MenuUrl.WORKFLOW_TASK_QUEUE_PAGE,
        key: MenuUrl.WORKFLOW_TASK_QUEUE_PAGE,
        resourceKey: RESOURCE_KEY.WORKFLOW_CORE,
      },
      {
        label: "Task Workbench",
        link: MenuUrl.WORKFLOW_WORKBENCH_PAGE,
        key: MenuUrl.WORKFLOW_WORKBENCH_PAGE,
        resourceKey: RESOURCE_KEY.WORKFLOW_CORE,
      },
    ],
  },
  {
    label: 'Segments',
    key: 'segment',
    icon: <UilObjectUngroup />,
    children: [
      {
        ...menuRegistry['crm_segments'],
        label:  "Segments"
      },
      {
        ...menuRegistry['crm_tags'],
        label:  "Tags"
      },
      {
        ...menuRegistry['crm_popupInfo'],
        label:  "Popups"
      },
      {
        ...menuRegistry['crm_contentTemplate'],
        label:  "Templates"
      },
      {
        ...menuRegistry['crm_customField'],
        label:  "Fields"
      },
    ],
  },
  {
    label: 'Campaigns',
    key: 'campaign',
    icon: <UilFire />,
    children: [
      {
        ...menuRegistry['crm_campaignSale'],
        label:  "Campaign Sale"
      },
      {
        ...menuRegistry['crm_campaignZone'],
        label:  "Campaign Zone"
      },
      {
        ...menuRegistry['crm_zone'],
        label:  "Zone"
      }
    ],
  },
  {
    label: 'Promotions',
    key: 'Promotions',
    icon: <UilGift />,
    children: [
      {
        ...menuRegistry['core_promotionRule'],
        label:  "Promotion Rules"
      },
      {
        ...menuRegistry['core_promotionCouponRedemption'],
        label:  "User Redemptions"
      }
    ],
  },
  {
    label: 'Notifications',
    key: 'Notifications',
    icon: <UilBell />,
    children: [
      {
        ...menuRegistry['notification_notificationSnapshot'],
        label:  "Message Center"
      },
      {
        ...menuRegistry['notification_notificationType'],
        label:  "Types"
      },
      {
        ...menuRegistry['notification_notificationModule'],
        label:  "Modules"
      },
      {
        ...menuRegistry['notification_notificationTypeGroup'],
        label: "Type Groups"
      },
      {
        ...menuRegistry['notification_notificationMsgTemplate'],
        label: "Message Templates"
      }
    ],
  },
  {
    label: 'Crm',
    key: 'Crm',
    icon: <UilObjectGroup />,
    children: [
      {
        ...menuRegistry['crm_userFeedback'],
        label:  "User Feedbacks"
      },
      {
        ...menuRegistry['core_contactForm'],
        label:  "Contact Form"
      },
      {
        ...menuRegistry['notification_smSubscribeTracking'],
        label:  "Subscriptions Form"
      },
      {
        ...menuRegistry['crm_wallOfLove'],
        label:  "Wall Of Loves"
      },
      {
        ...menuRegistry['crm_companyReport'],
        label:  "Company PDF"
      }
    ],
  },
  {
    label: 'Users',
    key: 'Users',
    icon: <UilUsersAlt />,
    children: [
      {
        ...menuRegistry['core_user'],
        label: "All Users"
      },
      {
        ...menuRegistry['core_membershipPlanUser'],
        label: "Memberships",
      },
      {
        ...menuRegistry['core_membershipSubscriptionUser'],
        label: "Membership Subscriptions",
      },
      {
        ...menuRegistry['core_securitiesAccount'],
        label: "Securities Accounts",
      },
      {
        ...menuRegistry['core_securitiesAccountForm'],
        label: "Securities Forms",
      },
      {
        ...menuRegistry['core_userForceSession'],
        label: "User Force Sessions",
      },
    ],
  },
  {
    label: 'Orders',
    key: 'Orders',
    icon: <UilShoppingBag />,
    children: [
      {
        ...menuRegistry['core_order'],
        label: "All Orders",
      }
    ],
  },
  {
    label: 'Credits',
    key: 'Credits',
    icon: <UilMoneyStack />,
    children: [
      {
        ...menuRegistry['core_userCash'],
        label: "All User Credits",
      },
      {
        ...menuRegistry['core_userCashDetail'],
        label: "Credit Detail",
      },
      {
        ...menuRegistry['core_userCashTransaction'],
        label: "Credit Transactions",
      },
      {
        ...menuRegistry['personalize_nebulaTradingTransaction'],
        label: "Nebula Transactions",
      },
      {
        ...menuRegistry['personalize_nebulaRewardTransaction'],
        label: "Nebula Rewards"
      }
    ],
  },
  {
    label: 'User Resources',
    key: 'User Resources',
    icon: <UilChartPie />,
    children: [
      {
        ...menuRegistry['personalize_portfolioList'],
        label: "All Portfolios"
      },
      {
        ...menuRegistry['personalize_portfolioListIntegration'],
        label: "Portfolio Integrations"
      },
      {
        ...menuRegistry['personalize_nebulaUserProfile'],
        label: "Nebula User Profile"
      },
      {
        ...menuRegistry['personalize_watchlist'],
        label: "All Watchlists"
      },
      {
        ...menuRegistry['personalize_userScreener'],
        label: "User Screeners"
      },
      {
        ...menuRegistry['personalize_companyNote'],
        label: "User Notes"
      },
      {
        ...menuRegistry['core_userReferral'],
        label: "User Referrals"
      },
      {
        ...menuRegistry['personalize_userWorkspace'],
        label: "User Workspaces"
      }
    ],
  },
  {
    label: 'Cms',
    key: 'Cms',
    icon: <UilBooks />,
    children: [
      {
        ...menuRegistry['community_featuredContent'],
        label: "Featured Content"
      },
      {
        ...menuRegistry['community_course'],
        label: "Courses"
      },
      {
        ...menuRegistry['community_courseReview'],
        label: "Reviews"
      },
      {
        ...menuRegistry['community_courseStudent'],
        label: "Students"
      },
    ],
  },
  {
    label: 'Company',
    key: 'Company',
    icon: <UilNewspaper />,
    children: [
      {
        ...menuRegistry['company_newsArticleHome'],
        label: "News"
      },
      {
        ...menuRegistry['company_company'],
        label: "Company"
      },
      {
        ...menuRegistry['company_seIndexSnapshot'],
        label: "Market Index"
      },
      {
        ...menuRegistry['company_stockAnalysisReport'],
        label: "Analysis Report"
      },
      {
        ...menuRegistry['company_bcIndustry'],
        label: "Industry"
      },
      {
        ...menuRegistry['company_bcIndustryGroup'],
        label: "Industry Group"
      },
      {
        ...menuRegistry['company_bcBusinessSector'],
        label: "Business Sector"
      },
      {
        ...menuRegistry['company_bcEconomicSector'],
        label: "Economic Sector"
      },
      {
        ...menuRegistry['company_goldExchange'],
        label: "Gold Exchange"
      },
      {
        ...menuRegistry['company_goldSnapshot'],
        label: "Gold Snapshot"
      },
      {
        ...menuRegistry['company_extractFinanceStatement'],
        label: "Extract Finance Report"
      },
    ],
  },
  {
    label: 'Settings',
    key: 'Settings',
    icon: <UilSetting />,
    children: [
      {
        ...menuRegistry['aitools_aiVideoGenerator'],
        label: "Video Generator"
      },
      {
        ...menuRegistry['core_membershipPlan'],
        label: "Memberships Plans"
      },
      {
        ...menuRegistry['core_membershipCashPlan'],
        label: "Memberships Cash Config"
      },
      {
        ...menuRegistry['core_userCashPlan'],
        label: "Credit Plans"
      },
      {
        ...menuRegistry['core_userReferralPlan'],
        label: "Referral Plans"
      },
      {
        ...menuRegistry['core_userReferralReward'],
        label: "Referral Rewards"
      },
      {
        ...menuRegistry['core_paymentMode'],
        label: "Payment Modes"
      },
      {
        ...menuRegistry['personalize_taSignalConfig'],
        label: "Ta Signals"
      },
      {
        ...menuRegistry['personalize_taIndicatorConfig'],
        label: "Ta Indicators"
      },
      {
        ...menuRegistry['search_widgetDocumentSearch'],
        label: "Widget Index"
      },
      {
        ...menuRegistry['core_miniAppConfig'],
        label: "Mini App"
      },
      {
        ...menuRegistry['core_appReleaseNotices'],
        label: "App Release Notices"
      },
      {
        ...menuRegistry['core_globalConfig'],
        label: "Global Config"
      },
    ],
  },
  {
    label: 'System Logs',
    key: 'System Logs',
    icon: <UilFilesLandscapesAlt />,
    children: [
      {
        ...menuRegistry['core_integrationLog'],
        label: "Logs Core"
      },
      {
        ...menuRegistry['personalize_integrationLog'],
        label: "Logs Personalize"
      },
      {
        ...menuRegistry['notification_otpTracking'],
        label: "Otp Tracking"
      },
      {
        ...menuRegistry['notification_smsTrackingLog'],
        label: "Sms Tracking"
      }
    ],
  },
];

export default menuConfig;
