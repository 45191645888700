import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const nebulaUserProfileMenuRegistry = {
    personalize_nebulaUserProfile: {
        label: "Nebula User Profile",
        link: PAGE_URL.NEBULA_USER_PROFILE_PAGE,
        key: PAGE_URL.NEBULA_USER_PROFILE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default nebulaUserProfileMenuRegistry;
