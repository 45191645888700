import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const goldSnapshotMenuRegistry = {
    company_goldSnapshot: {
        label: "Gold Snapshot",
        link: PAGE_URL.GOLD_SNAPSHOT_PAGE,
        key: PAGE_URL.GOLD_SNAPSHOT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default goldSnapshotMenuRegistry;
