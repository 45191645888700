import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const extractFinanceStatementMenuRegistry = {
    company_extractFinanceStatement: {
        label: "Extract Finance Statement",
        link: PAGE_URL.EXTRACT_FINANCE_STATEMENT_PAGE,
        key: PAGE_URL.EXTRACT_FINANCE_STATEMENT_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default extractFinanceStatementMenuRegistry;
