import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const goldExchangeMenuRegistry = {
    company_goldExchange: {
        label: "Gold Exchange",
        link: PAGE_URL.GOLD_EXCHANGE_PAGE,
        key: PAGE_URL.GOLD_EXCHANGE_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default goldExchangeMenuRegistry;
