import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const aiVideoGeneratorMenuRegistry = {
    aitools_aiVideoGenerator: {
        label: "Ai Video Generator",
        link: PAGE_URL.AI_VIDEO_GENERATOR_PAGE,
        key: PAGE_URL.AI_VIDEO_GENERATOR_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default aiVideoGeneratorMenuRegistry;
