import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const ExtractFinanceStatement = React.lazy(() =>
    import('../ExtractFinanceStatement')
);
const companyRoutesRegistry = [
    {
        path: PAGE_URL.EXTRACT_FINANCE_STATEMENT_PAGE,
        element: <ExtractFinanceStatement />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default companyRoutesRegistry;
