import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const GoldExchange = React.lazy(() =>
    import('../GoldExchange')
);
const goldExchangeRoutesRegistry = [
    {
        path: PAGE_URL.GOLD_EXCHANGE_PAGE,
        element: <GoldExchange />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default goldExchangeRoutesRegistry;
