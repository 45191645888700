import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const GoldSnapshot = React.lazy(() =>
    import('../GoldSnapshot')
);
const goldSnapshotRoutesRegistry = [
    {
        path: PAGE_URL.GOLD_SNAPSHOT_PAGE,
        element: <GoldSnapshot />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default goldSnapshotRoutesRegistry;
