import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const membershipCashPlanMenuRegistry = {
    core_membershipCashPlan: {
        label: "Membership Cash Plan",
        link: PAGE_URL.MEMBERSHIP_CASH_PLAN_PAGE,
        key: PAGE_URL.MEMBERSHIP_CASH_PLAN_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default membershipCashPlanMenuRegistry;
