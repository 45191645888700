import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const promptGenAiMenuRegistry = {
    company_promptGenAi: {
        label: "Prompt Gen Ai",
        link: PAGE_URL.PROMPT_GEN_AI_PAGE,
        key: PAGE_URL.PROMPT_GEN_AI_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default promptGenAiMenuRegistry;
