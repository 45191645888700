import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const nebulaUserProfileHistoryMenuRegistry = {
    personalize_nebulaUserProfileHistory: {
        label: "Nebula User Profile History",
        link: PAGE_URL.NEBULA_USER_PROFILE_HISTORY_PAGE,
        key: PAGE_URL.NEBULA_USER_PROFILE_HISTORY_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default nebulaUserProfileHistoryMenuRegistry;
