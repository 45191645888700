import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const NebulaUserProfileHistory = React.lazy(() =>
    import('../NebulaUserProfileHistory')
);
const nebulaUserProfileHistoryRoutesRegistry = [
    {
        path: PAGE_URL.NEBULA_USER_PROFILE_HISTORY_PAGE,
        element: <NebulaUserProfileHistory />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default nebulaUserProfileHistoryRoutesRegistry;
